:root {
  --primary: white;
}
.btn {
  font-size: 20px;
  width: 200px;
  background: rgb(0, 0, 0);
  border-radius: 25px;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.btn--outline {
  color: rgb(207, 207, 207);
  padding: 20px;
  /* border: 2px solid var(--primary); */
  transition: all 0.3s ease-out;
  width: 100%;
  font-size: 14pt;
}

.btn:hover,
.btn:hover {
  /* transition: all 0.3s ease-in-out; */
  background: rgb(170, 170, 170);
  color: white;
  /* background: #e20dc9; */
  /* --primary: #e20dc9; */
  /* color: white; */
}
