.team-container {
  margin-top: 12vh;
  margin-bottom: 12vh;
}

.category-img-container-2 {
  text-align: center;
  /* height: 22vh; */
  overflow: hidden;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.category-img-2 {
  display: inline-block;
  width: 250px;
  height: 250px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  border-radius: 100%;
  margin-top: 30px;
}

.team-text {
  text-align: left;
  margin-bottom: 0;
}
.indent {
  text-indent: 30px;
}

.name {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.social-icon-2 {
  /* width: 70px; */
  font-size: x-large;
  text-decoration: none;
}

.social-name {
  /* flex-flow: column; */
  /* padding: 25px 0px; */
  margin: 0;
  padding: 0;
  flex-direction: column;
  display: flex;
  align-items: flex-start;
}
.about-description {
  color: black;
  /* margin-top: 30px; */
  /* margin-bottom: 50px; */
  margin: 30px 20px;
}

.about-job-header {
  color: black;
}
