.home-container {
  position: relative;
  width: 100%;
  /* display: flex; */
  /* flex-direction: column; */
}

.home-subtext {
  padding: 40px;
  /* text-indent: 40px; */
  font-weight: 400;
}

.btn-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.home-btn {
  /* justify-content: center; */
  /* align-items: center; */
  width: 60px;
}

.home-title {
  text-align: center;
  margin: 200px 0px;
  font-size: 100px;
  margin-bottom: 10%;
}

.home-subcontainer {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-sub-title {
  text-align: center;
  font-size: 30px;
  margin: 20px;
}
.home-sub-title-2 {
  text-align: center;
  font-size: 40px;
  /* margin-bottom: 20px; */
  color: gray;
}

.home-text {
  font-size: 30px;
  text-align: center;
  /* margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 20%; */
  margin: 40px;
}

.text {
  text-align: left;
  margin-left: 10%;
  font-size: small;
}

.img-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* align-content: space-between; */
  justify-content: center;
  max-width: 100%;
  margin: 4vh 0;
}

.home-img {
  padding: 20px;
  height: 200px;
}
